<template>
  <div style="height: 100%; overflow-y: auto">
    <!-- 过滤区域 -->
    <box-item>
      <div class="filter-container">
        <div class="filter-input-container">
          <!-- 区域 -->
          <div class="input-item">
            <div class="input-item-title">区域</div>
            <el-select v-model="v1">
              <el-option
                v-for="item of spaces"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>

      <div class="input-item">
            <div class="input-item-title">租户</div>
            <el-select v-model="v7">
              <el-option
                v-for="item of tenants"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!-- <el-input></el-input> -->
        </div>

        <el-button type="primary" @click="loadData">提交</el-button>
      </div>
    </box-item>

  

 

    <!-- 表格 -->
    <box-item title="详细数据" style="margin-top:12px">
      <el-table :data=tenants height="500px" width=100%>
        <el-table-column  prop="name"
        label="租户"
       ></el-table-column>

          <el-table-column 
          label="空间"
       >
        <template>空间一</template>
       </el-table-column>

          <el-table-column 
          label="金额(元)"
       >
        <template>{{Math.ceil(Math.random()*10000)}}</template>
       </el-table-column>
      </el-table>
    </box-item>

 
  </div>
</template>

<script setup>
import { ref } from "vue";
import { RandomNumber } from "@/utils";
import moment from "moment"
const v1 = ref(null);
const v2 = ref(null);
const v3 = ref(null);
const v4 = ref(null);
const v5 = ref(null);
const v6=ref(1)
const v7=ref(null)

const data1 = ref([
  {
    name: "报告期消耗 吨标准煤 (TCE)",
    value: 0,
  },
  {
    name: "报告期消耗 吨二氧化碳排放 (TCO2E)",
    value: 0,
  },
  {
    name: "标准煤单位面积值 (TCE/M²)",
    value: 0,
  },
  {
    name: "二氧化碳单位面积值 (TCO2E/M²)",
    value: 0,
  },
]);

const data2 = ref([
  {
    name: "报告期消耗 吨标准煤 (TCE)",
    value: 0,
  },
  {
    name: "报告期消耗 吨二氧化碳排放 (TCO2E)",
    value: 0,
  },
  {
    name: "标准煤单位面积值 (TCE/M²)",
    value: 0,
  },
]);

const data3 = ref([]);
const data4 = ref([]);

const loadData1 = () => {
  let data = data1.value;
  data[0].value = RandomNumber(10, 2);
  data[1].value = RandomNumber(1000, 2);
  data[2].value = RandomNumber(500, 2);
  data[3].value = RandomNumber(500, 2);
};

const loadData2 = () => {
  let data = data2.value;
  data[0].value = 100;
  data[1].value = RandomNumber(100, 0);
  data[2].value = 100 - data[1].value;
};
const loadData3 = () => {
    let dateTime=new Date()*1
    let array=[]
    for(let index=0;index<100;index++){
        dateTime-=1000*3600*24
        array.push({
            name:moment(new Date(dateTime)).format("YYYY-MM-DD hh:mm:ss"),
            value:400+RandomNumber(100,2)
        })
    }
    data3.value=array
};

const loadData4 = () => {
    let dateTime=new Date()*1
    let array=[]
    for(let index=0;index<40;index++){
        dateTime-=1000*3600*24
        array.push({
            name:moment(new Date(dateTime)).format("YYYY-MM-DD hh:mm:ss"),
            value:400+RandomNumber(100,2)
        })
    }
    data4.value=array
};

const loadData = () => {
  loadData1();
  loadData2();
  loadData3();
  loadData4()
};

loadData();
</script>